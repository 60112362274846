import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { Box, Button, Center, Image } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import ExternalLink from './ExternalLink';
import ClickableMap, { ClickableMapAreaProps } from './ClickableMap';

export default function PostMotivateBanner({
  gaEventLabel,
}: {
  gaEventLabel: string;
}) {
  const [isClient, setIsClient] = useState(false);

  const noteLink = 'https://note.com/antaa/m/m3b899fb4c8fe';
  const noteLinkLabel = '投稿者インタビュー';
  const postLink = '/article/upload';
  const postLinkLabel = '今すぐ投稿';

  const clickableMapAreas: ClickableMapAreaProps[] = [
    {
      href: noteLink,
      onClick: () => {
        sendAnalyticsEvent('slide', 'post_banner', 'your_ga_event_label');
      },
      title: noteLinkLabel,
      children: (
        <Button
          as="span"
          h={[6, 10, 12]}
          w={['100%']}
          fontSize={['x-small', 'large', 'auto']}
          rightIcon={<ExternalLinkIcon />}
          colorScheme="brand"
          backgroundColor="white"
          variant="outline"
        >
          {noteLinkLabel}
        </Button>
      ),
      left: 90,
      top: 440,
      width: 1020,
      height: 100,
      target: '_blank',
    },
    {
      href: postLink,
      onClick: () => {
        sendAnalyticsEvent('slide', 'post_banner', 'your_ga_event_label');
      },
      title: postLinkLabel,
      children: (
        <Button
          as="a"
          colorScheme="brand"
          h={[6, 10, 12]}
          w={['100%']}
          fontSize={['x-small', 'large', 'auto']}
          pos="absolute"
          onClick={() => {
            sendAnalyticsEvent('slide', 'post_banner', gaEventLabel);
          }}
        >
          {postLinkLabel}
        </Button>
      ),
      left: 90,
      top: 500,
      width: 1020,
      height: 100,
      target: '_self',
    },
  ];

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <Box w="full" pos="relative">
      {/* for Mobile */}
      <Box display={[null, null, 'none', 'none']}>
        <Center pos="relative">
          <ClickableMap
            imageSize={{ width: 1200, height: 800 }}
            clickableMapAreas={clickableMapAreas}
          >
            <Image
              src="/images/banner_img_antaa_slide_post_motivate_SP.png"
              alt="Clickable Map Image"
            />
          </ClickableMap>
        </Center>
      </Box>

      {/* for PC */}
      <Box display={['none', null, 'block', 'block']}>
        <NextImage
          src="/images/banner_img_antaa_slide_post_motivate_PC.png"
          alt="Antaa Slide Post Banner"
          width={1984}
          height={592}
        />
        <ExternalLink
          href={noteLink}
          onClick={() => {
            sendAnalyticsEvent('slide', 'post_banner', gaEventLabel);
          }}
          withIcon={false}
        >
          <Button
            as="span"
            w={250}
            pos="absolute"
            bottom={8}
            right={300}
            colorScheme="brand"
            backgroundColor="white"
            variant="outline"
            rightIcon={<ExternalLinkIcon />}
          >
            {noteLinkLabel}
          </Button>
        </ExternalLink>
        <NextLink href={postLink} passHref>
          <Button
            as="a"
            w={250}
            pos="absolute"
            bottom={8}
            right={8}
            colorScheme="brand"
            onClick={() => {
              sendAnalyticsEvent('slide', 'post_banner', gaEventLabel);
            }}
          >
            {postLinkLabel}
          </Button>
        </NextLink>
      </Box>
    </Box>
  );
}
