import { Box, Grid, GridItem, Link, Text } from '@chakra-ui/react';
import NextImage from 'next/image';
import { useEffect, useState } from 'react';
import { URL_STORE_APPLE, URL_STORE_GOOGLE } from '../common/constants';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

type DeviceType = 'android' | 'ios';

function getDeviceType(): DeviceType | null {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (
    userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('ipad') > -1 ||
    userAgent.indexOf('ipod') > -1
  ) {
    return 'ios';
  }

  if (userAgent.indexOf('android') > -1) {
    return 'android';
  }

  return null;
}

function AppDownloadBar({ device }: { device: DeviceType }) {
  const url = device === 'ios' ? URL_STORE_APPLE : URL_STORE_GOOGLE;

  const handleLinkClick = () => {
    if (device === 'ios') {
      sendAnalyticsEvent('link_to', 'store', 'apple_from_bottom_bar');
    } else {
      sendAnalyticsEvent('link_to', 'store', 'google_from_bottom_bar');
    }
  };

  return (
    <Box
      bg="footer"
      position="fixed"
      bottom={1}
      right={1}
      zIndex="900"
      style={{
        width: '98%',
      }}
    >
      <Box
        bg="brand.500" // brand.500
        py={4}
        px={3}
        rounded={['lg', 'xl', null]}
      >
        <Link href={url} isExternal onClick={handleLinkClick}>
          <Grid templateColumns="repeat(24, 1fr)" gap={2}>
            <GridItem colSpan={3}>
              <NextImage
                src="/images/icon_mobile_app.png"
                alt="antaa公式アプリ"
                width={60}
                height={60}
              />
            </GridItem>
            <GridItem colSpan={18}>
              <Box>
                <Text
                  fontSize={['md', '2xl', null]}
                  fontWeight="bold"
                  color="white"
                >
                  公式アプリ
                </Text>
                <Text fontSize={[11, 'lg', null]} color="white">
                  スライド、計算機能ほか、オンデマンド配信見放題
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={3}>
              <NextImage
                src="/images/download_app_icon.png"
                width={60}
                height={60}
                alt="antaa公式アプリインストール"
              />
            </GridItem>
          </Grid>
        </Link>
      </Box>
    </Box>
  );
}

export default function MobileAppDownloadBar() {
  const [device, setDevice] = useState<DeviceType | null>(null);

  useEffect(() => {
    setDevice(getDeviceType());
  }, []);

  if (!device) {
    return null;
  }

  return <AppDownloadBar device={device} />;
}
