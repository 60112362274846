import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, HeadingProps } from '@chakra-ui/react';
import * as date from '../server/libs/date';
import monitoring from '../common/libs/monitoring';
import ArticlesGrid, { ArticleHeader } from '../components/ArticlesGrid';
import useApiClient from '../hooks/useApiClient';
import { useAppContext } from '../contexts/app';

const BADGE_SHOW_HOUR = 48;

function SectionHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading {...rest} fontSize="2xl">
      {children}
    </Heading>
  );
}

function RecommendedArticlesByViewHistories() {
  const { apiClient } = useApiClient();
  const [articles, setArticles] = useState<ArticleHeader[]>([]);
  const { isAuthenticated } = useAppContext();

  const callRecommendationViewHistoriesApi = useCallback(async () => {
    const res = await apiClient(`/api/article/recommendation/view-histories`);
    if (res.ok) {
      const data = await res.json();
      setArticles(data);
    } else {
      throw new Error();
    }
  }, [apiClient]);

  useEffect(() => {
    if (isAuthenticated) {
      callRecommendationViewHistoriesApi().catch((e) =>
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: '閲覧履歴からのおすすめスライドの取得に失敗しました。',
        }),
      );
    }
  }, [isAuthenticated, callRecommendationViewHistoriesApi]);

  if (articles.length < 1) {
    return null;
  }

  const articlesWithBadgeType: ArticleHeader[] = articles.map((article) => {
    const createDate = new Date(article.createDate);
    const updateDate = new Date(article.updateDate);
    if (
      !date.isAfterDiffHourSinceTargetDateToCurrentDate(
        BADGE_SHOW_HOUR,
        createDate,
      )
    ) {
      return {
        ...article,
        badgeType: 'new',
      };
    } else if (
      !date.isAfterDiffHourSinceTargetDateToCurrentDate(
        BADGE_SHOW_HOUR,
        updateDate,
      )
    ) {
      return {
        ...article,
        badgeType: 'updated',
      };
    }
    return article;
  });

  return (
    <Box>
      <SectionHeading mb={2}>閲覧履歴からのおすすめスライド</SectionHeading>
      <ArticlesGrid
        articles={articlesWithBadgeType}
        gaEventLabel={'recommended_articles_by_view_histories_from_top'}
      />
    </Box>
  );
}

export default RecommendedArticlesByViewHistories;
